var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("กระเป๋าเงิน")])]),_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('v-menu',{ref:"menuFilterDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedFilterDateFormatted,"label":"วันที่บันทึก","placeholder":"ระบุวันที่บันทึก","append-outer-icon":"mdi-calendar-month","readonly":"","required":""},on:{"click:append-outer":function($event){_vm.menuFilterDate = true}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFilterDate),callback:function ($$v) {_vm.menuFilterDate=$$v},expression:"menuFilterDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.menuFilterDate = false}},model:{value:(_vm.filterData.date),callback:function ($$v) {_vm.$set(_vm.filterData, "date", $$v)},expression:"filterData.date"}})],1)],1)],1)],1),_c('strong',[_vm._v(_vm._s(("วันที่: " + (_vm.moment().add(543, 'year').locale('th').format('D MMMM YYYY')) + " เวลา: " + (_vm.moment().locale('th').format('HH.mm น.')))))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item.wallet_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.wallet_date).add(543, 'year').format('DD/MM/YYYY')))]}},{key:"item.wallet_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.wallet_time).format('H.mm น.')))]}},{key:"item.wallet_savedby",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.wallet_savedby.name))]}},{key:"item.wallet_costprice",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatThaiBaht(item.wallet_costprice)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":function($event){return _vm.viewwallet(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editwallet(item)}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }