<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">กระเป๋าเงิน</h4>
      </div>

     <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วันที่บันทึก"
                  placeholder="ระบุวันที่บันทึก"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <strong>{{`วันที่: ${moment().add(543, 'year').locale('th').format('D MMMM YYYY')} เวลา: ${moment().locale('th').format('HH.mm น.')}`}}</strong>

      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.wallet_date="{ item }">{{moment(item.wallet_date).add(543, 'year').format('DD/MM/YYYY')}}</template>
      <template v-slot:item.wallet_time="{ item }">{{moment(item.wallet_time).format('H.mm น.')}}</template>
      <template v-slot:item.wallet_savedby="{ item }">{{item.wallet_savedby.name}}</template>
      <template v-slot:item.wallet_costprice="{ item }"><div class="text-end">{{formatThaiBaht(item.wallet_costprice)}}</div></template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-3"
          icon
          @click="viewwallet(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editwallet(item)"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      loaderAdd: false,
      data: [{
        id: '0000',
        no: 1,
        wallet_name: 'เงินสด',
        wallet_type: 'เงินสด',
        wallet_date: moment(),
        wallet_time: moment(),
        wallet_savedby: {
          name: 'Admin_WG001'
        },
        wallet_costprice: 40000
      }, {
        id: '0001',
        no: 2,
        wallet_name: 'บัตรเครดิต',
        wallet_type: 'ธนาคาร',
        wallet_date: moment(),
        wallet_time: moment(),
        wallet_savedby: {
          name: 'Admin_WG001'
        },
        wallet_costprice: 50000
      }, {
        id: '0002',
        no: 3,
        wallet_name: 'คิวอาร์โค้ด',
        wallet_type: 'ธนาคาร',
        wallet_date: moment(),
        wallet_time: moment(),
        wallet_savedby: {
          name: 'Admin_WG001'
        },
        wallet_costprice: 20000
      }, {
        id: '0003',
        no: 4,
        wallet_name: 'เงินโอน',
        wallet_type: 'ธนาคาร',
        wallet_date: moment(),
        wallet_time: moment(),
        wallet_savedby: {
          name: 'Admin_WG001'
        },
        wallet_costprice: 40000
      }],
      menuSelectDate: false,
      filterData: {
        date: new Date().toISOString().substr(0, 10),
        search: '',
        doctor: '',
        status: ''
      },
      menuFilterDate: false
    }
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    },
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    viewwallet (item) {
      console.log('view: ', item)
    },
    editwallet (item) {
      console.log('edit: ', item)
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'รายการ',
          align: 'center',
          value: 'wallet_name',
          sortable: false
        }, {
          text: 'ประเภท',
          align: 'center',
          value: 'wallet_type',
          sortable: false
        }, {
          text: 'วันที่บันทึก',
          align: 'center',
          value: 'wallet_date',
          sortable: false
        }, {
          text: 'เวลาบันทึก',
          align: 'center',
          value: 'wallet_time',
          sortable: false
        }, {
          text: 'ผู้บันทึก',
          align: 'center',
          value: 'wallet_savedby',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'wallet_costprice',
          sortable: false
        }, {
          text: 'ปรับปรุงยอด',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    }
  }
}
</script>
